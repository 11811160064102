@use "../../../mixins.scss" as mixins;

.shipmentMatchTable {
  border-spacing: 0 6px !important;
  background-color: #ebebec !important;

  th {
    &.shipmentCount {
      padding: 0.5em 0.7em !important;
      text-align: center !important;
    }

    &:not(.shipmentCount) {
      @include mixins.table-header-style;
    }
  }

  td {
    background-color: rgb(255, 255, 255);

    &.capacityCell {
      background-color: rgb(249, 250, 251);
      font-weight: 600;
      font-style: italic;
    }

    &.matchItems {
      max-width: 300px;
    }
  }

  .truncate {
    @include mixins.text-truncate;
  }
}
