// toast colors //
$wrong-red: #f85e7c;
$right-green: #349b68;

// RPM Brand colors
$rpm-profiles-blue: #0e1e39;

// Dark Blue:
// light
$rpm-dark-blue-tint3: #e8ebef;
$rpm-dark-blue-tint2: #a5aec0;
$rpm-dark-blue-tint1: #627192;
// dark
$rpm-dark-blue-shade1: #1a2a4f;
$rpm-dark-blue-shade2: #131f3b;
$rpm-dark-blue-shade3: #0d1427;

// Detroit Blue:
// light
$rpm-detroit-blue-tint3: #e7f1f7;
$rpm-detroit-blue-tint2: #99c8e1;
$rpm-detroit-blue-tint1: #589ecc;
// dark
$rpm-detroit-blue-shade1: #1b5c91;
$rpm-detroit-blue-shade2: #12456d;
$rpm-detroit-blue-shade3: #1a2a4f;

// Gunmetal:
// light
$rpm-gunmetal-tint3: #ebebec;
$rpm-gunmetal-tint2: #adb0b2;
$rpm-gunmetal-tint1: #707679;
// dark
$rpm-gunmetal-shade1: #282f33;
$rpm-gunmetal-shade2: #1e2326;
$rpm-gunmetal-shade3: #141719;

// Bronze:
// light
$rpm-bronze-tint3: #f8eee6;
$rpm-bronze-tint2: #e7bd9c;
$rpm-bronze-tint1: #d58b53;
// dark
$rpm-bronze-shade1: #9c4807;
$rpm-bronze-shade2: #753605;
$rpm-bronze-shade3: #4e2403;
