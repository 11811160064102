@import "./styles/colors.scss";

@mixin text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin preferred-button-style-of-profiles-jesus-joshua-trantham {
  padding: 5px 10px !important;
  border-radius: 40px !important;
}

@mixin table-header-style {
  background-color: $rpm-profiles-blue !important;
  border: 1px solid #ebebec !important;
  text-align: start !important;
  color: white !important;
}
