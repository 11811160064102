@use "../../mixins.scss" as mixins;

.carrierListTableContainer {
  padding: 0 6px;
  height: 100%;
  overflow-y: scroll;

  &.loading {
    cursor: wait;
  }

  &:not(.loading) {
    td:not(.informationCell) {
      cursor: pointer;
    }
  }

  table {
    border-spacing: 0 6px !important;
    background-color: #ebebec !important;
    border: 0px !important;
  }

  thead {
    tr {
      th {
        position: sticky !important;
        top: 0px;
        z-index: 2;

        &.carrierCount {
          padding: 0.5em 0.7em;
          text-align: center;
        }

        &.headerCell {
          @include mixins.table-header-style;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover:not(:has(td.informationCell)) {
        box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.15);
      }

      &.activeRow {
        outline: 2px solid #2185d0;
      }

      td {
        background-color: white;
        border: 0px !important;

        // Allow positioning Turvo status icon
        &:first-of-type {
          position: relative;

          .sizedIcon {
            width: 15px;
            height: 15px;
          }
        }

        &.truncate,
        &.truncate div {
          @include mixins.text-truncate;
        }

        &.informationCell {
          text-align: center;
          font-weight: 600;
        }

        &.loadingRow {
          height: 53px;
        }

        button {
          text-wrap: nowrap;
          padding: 5px 10px !important;
          border-radius: 40px !important;
        }

        .carrierName {
          font-weight: 700;
        }

        .grey {
          color: grey;

          &.isCurrentUser {
            color: #2185d0;
          }
        }
      }
    }
  }
}

.carrierListTableContainerLarge {
  table {
    tbody {
      tr {
        td {
          &.carrierNameCell {
            max-width: 230px;
          }

          &:not(.carrierNameCell) {
            max-width: 200px;
          }

          div:global(.ribbon.label) {
            display: flex;
            align-items: center;
            gap: 5px;
            padding-left: 14px;
            padding-right: 14px;
            height: 26px;
            left: -14px !important;
            margin-top: -3px;

            img.turvoLogo {
              width: 14px !important;
              height: 14px !important;
            }
          }
        }
      }
    }
  }
}

.carrierListTableContainerSmall {
  table {
    tbody {
      tr {
        td {
          &:first-of-type {
            > div {
              display: flex;
              gap: 6px;
              flex-direction: column;
              align-items: center;
            }
          }

          &.carrierNameCell {
            max-width: 170px;
          }

          &:not(.carrierNameCell) {
            max-width: 145px;
          }
        }
      }
    }
  }
}
