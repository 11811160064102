.wrapper {
  background-color: rgba(14, 30, 57, 1);
  padding: 0 40px;
  font-family:
    Open Sans,
    sans-serif;
  display: flex;
  flex-direction: row;
  height: 60px;
}
