.logo {
  display: flex;
  align-items: center;
}

.navigationList {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  height: 100%;
  box-sizing: content-box;

  .link,
  .selectedLink {
    color: white;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    margin: 0 24px;
    height: 100%;
    display: flex;
    align-items: center;

    &:after {
      color: #f7941d;
      content: "";
      position: absolute;
      width: 0;
      bottom: 0;
      border-bottom: 4px solid #f7941d;
      transition-property: width;
      transition-duration: 0.1s;
      transition-timing-function: ease-out;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }

    .title {
      padding-right: 30px;
    }

    .img {
      position: absolute;
      top: 5px;
      right: -15px;
    }

    .navDropdown {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      background-color: rgba(14, 30, 57, 0.7);
      backdrop-filter: blur(30px);
      position: absolute;
      z-index: 1200;
      top: 60px;
      padding: 0 12px;
      width: 175px;

      .item,
      .selected {
        padding: 20px 12px;
        position: relative;
        color: inherit;

        &:after {
          color: #f7941d;
          content: "";
          position: absolute;
          width: 0;
          bottom: 0;
          left: 0;
          border-bottom: 4px solid #f7941d;
          transition-property: width;
          transition-duration: 0.1s;
          transition-timing-function: ease-out;
        }

        &:hover {
          &:after {
            width: 100%;
          }
        }
      }

      .selected {
        &:after {
          width: 100%;
        }
      }
    }
  }

  .selectedLink {
    &:after {
      width: 100%;
    }
  }
}

.authWrapper {
  margin-left: auto;
  margin-right: -15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  height: 100%;

  .title {
    color: white;
    font-size: 18px;
    font-weight: 600;
  }

  .authImg {
    color: white;
  }

  .authMenu {
    list-style-type: none;
    background-color: rgba(14, 30, 57, 0.7);
    backdrop-filter: blur(30px);
    position: absolute;
    z-index: 1200;
    top: 60px;
    padding: 0 12px;
    width: 170px;
    margin: 0;
    right: -25px;
    box-sizing: content-box;

    .logoutButton {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      text-align: left;
      cursor: pointer;
    }

    .item,
    .logoutButton {
      color: white;
      font-size: 18px;
      font-weight: 600;
      padding: 20px 12px;

      &:after {
        color: #f7941d;
        content: "";
        position: absolute;
        width: 0;
        bottom: 0;
        left: 0;
        border-bottom: 4px solid #f7941d;
        transition-property: width;
        transition-duration: 0.1s;
        transition-timing-function: ease-out;
      }

      &:hover {
        &:after {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .navigationList {
    .link {
      margin: 0 16px;
    }
  }
}

@media (max-width: 950px) {
  .navigationList {
    .link {
      margin: 0 10px;
      font-size: 16px;
    }
  }

  .authWrapper {
    .authMenu {
      .item {
        font-size: 16px;
      }
    }
  }
}
