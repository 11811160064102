@use "../../mixins.scss" as mixins;
@import "../../styles/colors.scss";

.carrierCapacity {
  flex: 1 1 auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid #22242626;
  padding: 8px;

  header {
    display: flex;
    align-items: center;
    gap: 10px;

    div:nth-of-type(1) {
      width: 210px;
      display: flex;
      align-items: center;
      gap: 6px;

      h2 {
        font-size: 1.25rem;
        margin-bottom: 0px;
      }
    }

    button {
      @include mixins.preferred-button-style-of-profiles-jesus-joshua-trantham;
    }
  }

  table {
    thead {
      font-size: 1rem;

      tr {
        th {
          padding: 8px 10px !important;
          @include mixins.table-header-style;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 8px 10px !important;
          max-width: 250px;
          @include mixins.text-truncate;

          &:first-child {
            width: 40px;
          }

          &:last-child {
            width: 122px;
          }

          :global div.line {
            max-height: 6px;
          }

          button {
            padding: 3px 6px !important;
            border-radius: 40px !important;
          }
        }
      }
    }
  }

  > div {
    overflow-x: auto;

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }
}
